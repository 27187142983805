@import './nprogress';
@import './colors';

$primary-color: #131313; // #1b73e8; // #000;
$secondary-color: #e00;
$success-color: #00924c;
$danger-color: #d00;
$warning-color: #f90;
$info-color: #0070f3;
$subtle-color: #eee;
$neutral-color: #888;

$twitter-color: #1da1f2;
$discord-color: #7289da;

// NEW
:root {
  --sw-bg-primary: #fff;
  --sw-bg-secondary: #fafafa;
  --sw-border: #e1e1e1;
  --sw-border-hover: #c1c1c1;
  --sw-border-active: #a1a1a1;
  --sw-text-primary: #1a1a1a;
  --sw-text-secondary: #616161;

  --sw-border-radius: 6px;
  --sw-input-height: 42px;

  --sw-primary: #161616;
  --sw-primary-light: #363636;
  --sw-primary-lighter: #424242;
  --sw-primary-dark: #131313;
  --sw-primary-darker: #000;
  --sw-primary-bg-text: #fff;

  --sw-outline-border: rgb(48 140 235 / 75%);
  --sw-outline-ring: rgb(48 165 235 / 25%);
  --sw-outline-box-shadow: 0 0 0 0.18rem var(--sw-outline-ring);
}

:root {
  // Color variants
  --primary-lighter-color: #{tint($primary-color, 20%)};
  --primary-light-color: #{tint($primary-color, 15%)};
  --primary-color: #{$primary-color};
  --primary-dark-color: #{shade($primary-color, 10%)};
  --primary-darker-color: #{shade($primary-color, 20%)};
  --primary-pale-color: #eee;
  --primary-alt-text-color: #000;
  --primary-background-text-color: #fff;
  --primary-button-outlined-border-color: #ccc;
  --primary-button-outlined-border-hover-color: #f6f6f6;

  --secondary-lighter-color: #{tint($secondary-color, 25%)};
  --secondary-light-color: #{tint($secondary-color, 15%)};
  --secondary-color: #{$secondary-color};
  --secondary-dark-color: #{shade($secondary-color, 10%)};
  --secondary-darker-color: #{shade($secondary-color, 20%)};
  --secondary-pale-color: #ffedeb;
  --secondary-alt-text-color: #d00;
  --secondary-background-text-color: #fff;

  --success-lighter-color: #{tint($success-color, 20%)};
  --success-light-color: #{tint($success-color, 10%)};
  --success-color: #{$success-color};
  --success-dark-color: #{shade($success-color, 10%)};
  --success-darker-color: #{shade($success-color, 20%)};
  --success-pale-color: #e3f2eb;
  --success-alt-text-color: #128000;
  --success-background-text-color: #fff;

  --danger-lighter-color: #{tint($danger-color, 25%)};
  --danger-light-color: #{tint($danger-color, 15%)};
  --danger-color: #{$danger-color};
  --danger-dark-color: #{shade($danger-color, 10%)};
  --danger-darker-color: #{shade($danger-color, 20%)};
  --danger-pale-color: var(--secondary-pale-color);
  --danger-alt-text-color: var(--secondary-alt-text-color);
  --danger-background-text-color: #fff;

  --warning-lighter-color: #{tint($warning-color, 25%)};
  --warning-light-color: #{tint($warning-color, 15%)};
  --warning-color: #{$warning-color};
  --warning-dark-color: #{shade($warning-color, 10%)};
  --warning-darker-color: #{shade($warning-color, 20%)};
  --warning-pale-color: #fff5e6;
  --warning-alt-text-color: rgb(229, 145, 18);
  --warning-background-text-color: #fff;

  --info-lighter-color: #{tint($info-color, 20%)};
  --info-light-color: #{tint($info-color, 10%)};
  --info-color: #{$info-color};
  --info-dark-color: #{shade($info-color, 10%)};
  --info-darker-color: #{shade($info-color, 20%)};
  --info-pale-color: #eef5fe;
  --info-alt-text-color: #0070f3;
  --info-background-text-color: #fff;

  --neutral-lighter-color: #{tint($neutral-color, 20%)};
  --neutral-light-color: #{tint($neutral-color, 10%)};
  --neutral-color: #{$neutral-color};
  --neutral-dark-color: #{shade($neutral-color, 10%)};
  --neutral-darker-color: #{shade($neutral-color, 20%)};
  --neutral-pale-color: pink; // TODO: pick color
  --neutral-alt-text-color: pink; // TODO: pick color
  --neutral-background-text-color: #fff;

  --subtle-lighter-color: #{tint($subtle-color, 20%)};
  --subtle-light-color: #{tint($subtle-color, 10%)};
  --subtle-color: #{$subtle-color};
  --subtle-dark-color: #{shade($subtle-color, 5%)};
  --subtle-darker-color: #{shade($subtle-color, 10%)};
  --subtle-alt-text-color: #333;
  --subtle-pale-color: #333;
  --subtle-background-text-color: #333;

  --transparent-lighter-color: #eee;
  --transparent-light-color: #ddd;
  --transparent-color: transparent;
  --transparent-dark-color: #eee;
  --transparent-darker-color: #ddd;
  --transparent-pale-color: #eee;
  --transparent-alt-text-color: #000;
  --transparent-background-text-color: #000;

  // Border/Divider
  --divider-color: #dedede;
  --border-color: #dedede;
  --border-hover-color: #bbb;
  --border-active-color: #000;

  --border-radius: 6px; //3px; // 3px;
  --page-background-color: #fff;
  --backdrop-color: #000;

  --text-dark-color: #000;
  --text-color: #000;
  --text-light-color: #666;
  --text-lighter-color: #999;

  --link-color: #000;
  --title-color: #000;
  --title-disabled-color: #888;

  --type-user-text-color: #fff;
  --type-user-background-color: #555;
  --type-creator-text-color: #fff;
  --type-creator-background-color: var(--secondary-color);

  --icon-badge-background-color: #d00;
  --icon-badge-text-color: #fff;

  --grey-icon-color: #888;
  --grey-icon-hover-color: #555;
  --grey-icon-active-color: #333;

  // <RangeInput>
  --range-input-primary-track-color: #{rgba($primary-color, 0.2)};
  --range-input-primary-border-color: #{rgba($primary-color, 0.075)};
  --range-input-secondary-track-color: #{rgba($secondary-color, 0.2)};
  --range-input-secondary-border-color: #{rgba($secondary-color, 0.15)};
  --range-input-success-track-color: #{rgba($success-color, 0.2)};
  --range-input-success-border-color: #{rgba($success-color, 0.15)};
  --range-input-danger-track-color: #{rgba($danger-color, 0.2)};
  --range-input-danger-border-color: #{rgba($danger-color, 0.15)};
  --range-input-warning-track-color: #{rgba($warning-color, 0.2)};
  --range-input-warning-border-color: #{rgba($warning-color, 0.15)};
  --range-input-info-track-color: #{rgba($info-color, 0.2)};
  --range-input-info-border-color: #{rgba($info-color, 0.15)};

  // <ProgressBar>
  --progress-bar-primary-track-color: #{rgba($primary-color, 0.2)};
  --progress-bar-secondary-track-color: #{rgba($secondary-color, 0.2)};
  --progress-bar-success-track-color: #{rgba($success-color, 0.2)};
  --progress-bar-danger-track-color: #{rgba($danger-color, 0.2)};
  --progress-bar-warning-track-color: #{rgba($warning-color, 0.2)};
  --progress-bar-info-track-color: #{rgba($info-color, 0.2)};

  // <Modal>
  --modal-background-color: #fff;
  --modal-backdrop-background-color: #000;
  --modal-close-icon-color: var(--grey-icon-color);
  --modal-close-icon-hover-color: var(--grey-icon-hover-color);
  --modal-close-icon-active-color: var(--grey-icon-active-color);
  --modal-border-radius: 3px;

  // <IconContainer>
  --icon-container-background-color: #eee;
  --icon-container-icon-color: #888;

  // font-size
  --font-size-10px: 0.625rem;
  --font-size-11px: 0.6875rem;
  --font-size-12px: 0.75rem;
  --font-size-13px: 0.8125rem;
  --font-size-14px: 0.875rem;
  --font-size-15px: 0.9375rem;
  --font-size-16px: 1rem;
  --font-size-17px: 1.0625rem;
  --font-size-18px: 1.125rem;
  --font-size-19px: 1.1875rem;
  --font-size-20px: 1.25rem;
  --font-size-21px: 1.3125rem;
  --font-size-22px: 1.375rem;
  --font-size-23px: 1.4375rem;
  --font-size-24px: 1.5rem;
  --font-size-25px: 1.5625rem;
  --font-size-26px: 1.625rem;
  --font-size-27px: 1.6875rem;
  --font-size-28px: 1.75rem;
  --font-size-29px: 1.8125rem;
  --font-size-30px: 1.875rem;
  --font-size-31px: 1.9375rem;
  --font-size-32px: 2rem;
  --font-size-33px: 2.0625rem;
  --font-size-34px: 2.125rem;
  --font-size-35px: 2.1875rem;
  --font-size-36px: 2.25rem;
  --font-size-37px: 2.3125rem;
  --font-size-38px: 2.375rem;
  --font-size-39px: 2.4375rem;
  --font-size-40px: 2.5rem;
  --font-size-41px: 2.5625rem;
  --font-size-42px: 2.625rem;
  --font-size-43px: 2.6875rem;
  --font-size-44px: 2.75rem;
  --font-size-45px: 2.8125rem;
  --font-size-46px: 2.875rem;
  --font-size-47px: 2.9375rem;
  --font-size-48px: 3rem;
  --font-size-49px: 3.0625rem;
  --font-size-50px: 3.125rem;
  --font-size-51px: 3.1875rem;
  --font-size-52px: 3.25rem;
  --font-size-53px: 3.3125rem;
  --font-size-54px: 3.375rem;
  --font-size-55px: 3.4375rem;
  --font-size-56px: 3.5rem;
  --font-size-57px: 3.5625rem;
  --font-size-58px: 3.625rem;
  --font-size-59px: 3.6875rem;
  --font-size-60px: 3.75rem;
  --font-size-61px: 3.8125rem;
  --font-size-62px: 3.875rem;
  --font-size-63px: 3.9375rem;
  --font-size-64px: 4rem;

  // line-heights;
  --line-height-for-12px: 20px;
  --line-height-for-13px: 21px;
  --line-height-for-14px: 22px;
  --line-height-for-15px: 23px;
  --line-height-for-16px: 24px;
  --line-height-for-18px: 26px;
  --line-height-for-20px: 28px;
  --line-height-for-24px: 30px;
  --line-height-for-30px: 40px; // not tested

  // font-weights
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-black: 900;

  // <NavBar>
  --nav-bar-height: 64px;
  --nav-bar-background-color: var(--primary-color);
  --nav-bar-logo-color: #fff;
  --nav-bar-icon-color: #fff;
  --nav-bar-divider-color: var(--divider-color);
  --nav-bar-link-text-color: #fff;
  --nav-bar-link-background-hover-color: #222;
  --nav-bar-link-background-active-color: #333;
  --nav-bar-loading-icon-color: #fff;
  --nav-bar-profile-picture-inner-border-color: #000;
  --nav-bar-profile-picture-outer-border-color: #fff;

  // <Menu>
  --menu-background-color: #fff;
  --menu-border-radius: 3px;

  // <MenuItem>
  --menu-divider-color: var(--divider-color);
  --menu-item-color: #333;
  --menu-item-hover-color: #000;
  --menu-item-active-color: #000;
  --menu-item-background-color: var(--menu-background-color);
  --menu-item-background-hover-color: #f6f6f6;
  --menu-item-background-active-color: #eee;

  // <Drawer>
  --drawer-width: 250px;
  --drawer-background-color: #fff;
  --drawer-backdrop-background-color: #000;

  // <UserDrawerContent>
  --user-drawer-background-color: var(--drawer-background-color);
  --user-drawer-toolbar-background-color: var(--user-drawer-background-color);
  --user-drawer-toolbar-link-text-color: #000;
  --user-drawer-toolbar-link-background-color: #fff;
  --user-drawer-toolbar-link-background-hover-color: #eee;
  --user-drawer-toolbar-link-background-active-color: #ddd;
  --user-drawer-item-color: var(--grey-icon-color);
  --user-drawer-item-hover-color: var(--grey-icon-hover-color);
  --user-drawer-item-active-color: var(--grey-icon-active-color);
  --user-drawer-item-background-color: #fff;
  --user-drawer-item-background-hover-color: #eee;
  --user-drawer-item-background-active-color: #ddd;
  --user-drawer-profile-picture-inner-border-color: #fff;
  --user-drawer-profile-picture-outer-border-color: #000;
  --user-drawer-profile-picture-icon-color: #fff;
  --user-drawer-name-color: #333;
  --user-drawer-name-hover-color: #000;
  --user-drawer-name-active-color: var(--user-drawer-name-hover-color);
  --user-drawer-stat-background-color: #f6f6f6;
  --user-drawer-stat-background-hover-color: var(
    --user-drawer-item-background-hover-color
  );
  --user-drawer-stat-background-active-color: var(
    --user-drawer-item-background-active-color
  );
  --user-drawer-stat-color: var(--user-drawer-item-color);
  --user-drawer-stat-hover-color: var(--user-drawer-item-hover-color);
  --user-drawer-stat-active-color: var(--user-drawer-item-active-color);
  --user-drawer-stat-label-color: #333;
  --user-drawer-stat-label-hover-color: var(--user-drawer-item-hover-color);
  --user-drawer-stat-label-active-color: var(--user-drawer-item-hover-color);
  --user-drawer-cta-item-color: var(--user-drawer-item-color);
  --user-drawer-cta-item-hover-color: var(--user-drawer-item-hover-color);
  --user-drawer-cta-item-active-color: var(--user-drawer-item-active-color);
  --user-drawer-cta-item-background-color: var(
    --user-drawer-item-background-hover-color
  );
  --user-drawer-cta-item-background-hover-color: var(
    --user-drawer-item-background-hover-color
  );
  --user-drawer-cta-item-background-active-color: var(
    --user-drawer-item-background-active-color
  );

  // <UserDrawerMenuItem>
  --user-drawer-menu-item-color: var(--user-drawer-item-color);
  --user-drawer-menu-item-hover-color: var(--user-drawer-item-hover-color);
  --user-drawer-menu-item-active-color: var(--user-drawer-item-active-color);
  --user-drawer-menu-item-selected-color: var(--secondary-color);
  --user-drawer-menu-item-background-color: var(--menu-item-background-color);
  --user-drawer-menu-item-background-hover-color: var(
    --menu-item-background-hover-color
  );
  --user-drawer-menu-item-background-active-color: var(
    --menu-item-background-active-color
  );

  // Input generic
  --input-background-color: #fff;
  --input-border-color: #bbb;
  --input-border-hover-color: #888;
  --input-focus-color: #000;
  --input-error-color: #d00;
  --input-error-background-color: #ffedeb;
  --input-placeholder-color: #aaa;

  // <PageTabBar>
  --page-tab-bar-divider-color: var(--divider-color);
  --page-tab-bar-background-color: var(--page-background-color);

  // <PageTab>
  --page-tab-color: #191919;
  --page-tab-hover-color: #000;
  --page-tab-active-color: #000;
  --page-tab-selected-color: var(--primary-color);

  // <Skeleton />
  --skeleton-background-color: #eee;
  --skeleton-background-hover-color: #ddd;
  --skeleton-color-1: #fafafa;
  --skeleton-color-2: #eaeaea;

  --crop-container-background-color: rgba(255, 255, 255, 0.8);
  --crop-area-border-color: #000;

  --dropzone-border-color: #aaa;

  --twitter-lighter-color: #{tint($twitter-color, 20%)};
  --twitter-light-color: #{tint($twitter-color, 15%)};
  --twitter-color: #{$twitter-color};
  --twitter-dark-color: #{shade($twitter-color, 10%)};
  --twitter-darker-color: #{shade($twitter-color, 20%)};

  --discord-lighter-color: #{tint($discord-color, 20%)};
  --discord-light-color: #{tint($discord-color, 15%)};
  --discord-color: #{$discord-color};
  --discord-dark-color: #{shade($discord-color, 10%)};
  --discord-darker-color: #{shade($discord-color, 20%)};
}

* {
  box-sizing: border-box;
}

html,
body {
  background-color: var(--page-background-color);
}

body {
  color: var(--sw-text-primary);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html.isLocked,
html.isLocked body {
  overflow: hidden;
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color);
}

button {
  outline: none;
}

strong {
  font-weight: var(--font-weight-bold);
}

a {
  font-weight: var(--font-weight-bold);
  color: var(--link-color);
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}

p {
  color: var(--text-color);
  line-height: var(--line-height-for-16px);
}

textarea {
  resize: none; /* user can resize vertically, but width is fixed */
}
